import { useState, useEffect } from "react";
import decodeUriComponent from "decode-uri-component";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import querySearch from "stringquery";
import HiddenInputs from "./hidden-inputs";
import CustomHiddenInputs from "./custom-hidden-inputs";
import { Container, Form, InputContainer } from "./styles";

interface Props {
  formId: number;
  autofocus?: boolean;
  secondarySchema?: boolean;
}

export default function ActiveCampaignForm({
  formId,
  autofocus = false,
  secondarySchema = false,
}: Props) {
  const location = useLocation();

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const [utmSource, setUtmSource] = useState("no-tracking");
  const [utmMedium, setUtmMedium] = useState("no-tracking");
  const [utmCampaign, setUtmCampaign] = useState("no-tracking");
  const [utmContent, setUtmContent] = useState("no-tracking");
  const [hsaAd, setHsaAd] = useState("no-tracking");
  const [hsaGrp, setHsaGrp] = useState("no-tracking");
  const [hsaCam, setHsaCam] = useState("no-tracking");
  const [hsaSrc, setHsaSrc] = useState("no-tracking");
  const [indicacaoEvento, setIndicacaoEvento] = useState("no-tracking");

  const url = "https://kenzie39049.activehosted.com/proc.php";
  const validatePhone = /\(\d{2}\)\s9\d{4}-\d{4}/g;

  const submitData = (e: any) => {
    if (!phoneNumber || !validatePhone.test(phoneNumber)) {
      e.preventDefault();

      if (phoneNumber === "") {
        setError("phone", { message: "Preencha esse campo obrigatório." });
      }

      if (validatePhone.test(phoneNumber) === false) {
        setError("phone", {
          message: "Insira seu número com DDD. Exemplo: (41) 9xxxx-xxxx",
        });
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    const urlParams = querySearch(location.search);

    setUtmSource(urlParams.utm_source);
    setUtmMedium(urlParams.utm_medium);
    setUtmCampaign(urlParams.utm_campaign);
    setUtmContent(urlParams.utm_content);
    setHsaAd(urlParams.hsa_ad);
    setHsaGrp(urlParams.hsa_grp);
    setHsaCam(urlParams.hsa_cam);
    setHsaSrc(urlParams.hsa_src);
    setIndicacaoEvento(() => {
      if (urlParams.indicacao_evento) {
        return decodeUriComponent(urlParams.indicacao_evento);
      }
      return urlParams.indicacao_evento;
    });
  }, [location.search]);

  useEffect(() => {
    if (phoneNumber !== "" && validatePhone.test(phoneNumber) === true) {
      clearErrors("phone");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <Container>
      <Form
        onSubmit={submitData}
        method="POST"
        action={url}
        id={"_form_" + formId + "_"}
        className={"_form _form_" + formId + " _inline-form "}
        secondary={secondarySchema}
      >
        <HiddenInputs formId={formId} />

        {errors.phone && <span className="errors">{errors.phone.message}</span>}

        <div className="boxes">
          <InputContainer secondary={secondarySchema}>
            <input
              id="fullname"
              type="text"
              name="fullname"
              value={name}
              placeholder="Digite seu nome"
              required
              onChange={(e) => setName(e.target.value)}
              autoFocus={autofocus}
            />

            <input
              id="email"
              type="email"
              name="email"
              value={email}
              placeholder="Digite seu melhor e-mail"
              required
              onChange={(e) => setEmail(e.target.value)}
              autoFocus={autofocus}
            />

            <InputMask
              mask="(99) 99999-9999"
              id="phone"
              type="text"
              name="phone"
              value={phoneNumber}
              placeholder="Digite seu telefone"
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </InputContainer>

          <CustomHiddenInputs
            values={[
              utmSource,
              utmMedium,
              utmCampaign,
              utmContent,
              hsaAd,
              hsaGrp,
              hsaCam,
              hsaSrc,
              indicacaoEvento,
            ]}
          />
          <button id="_form_63_submit" type="submit">
            Fazer inscrição gratuita
          </button>
        </div>

        <p className="terms">
          Na qualidade de Usuário declaro que li, compreendi e estou de acordo
          com os{" "}
          <a
            href="https://formulario-kenzie.vercel.app/termos-condicoes"
            target="_blank"
            rel="noreferrer"
          >
            Termos de Uso e com a Política de Privacidade
          </a>{" "}
          .
        </p>
      </Form>
    </Container>
  );
}
