import { Background, Container, Subtitle } from "./styles";
import titleMobile from "../../assets/img/title-mobile.webp";
import title from "../../assets/img/title.webp";
import ActiveCampaignForm from "../ActiveCampaignForm";

interface HeroProps {
  refProp: React.RefObject<HTMLHeadingElement>;
}

export default function Hero({ refProp }: HeroProps) {
  return (
    <Background ref={refProp}>
      <Container>
        <h1 title="Código da Virada">
          <picture>
            <source media="(min-width: 1024px)" srcSet={title} />
            <img
              src={titleMobile}
              alt="Código da Virada"
              width="283"
              height="122"
            />
          </picture>
        </h1>
        <Subtitle>
          Faça seu <strong>primeiro projeto de programação do zero</strong> e
          desperte para a vida que você sempre sonhou.
        </Subtitle>

        <ActiveCampaignForm formId={111} />
      </Container>
    </Background>
  );
}
