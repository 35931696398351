interface Props {
  values: string[];
}

export default function CustomHiddenInputs({ values }: Props) {
  const [
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    hsaAd,
    hsaGrp,
    hsaCam,
    hsaSrc,
    indicacaoEvento,
  ] = values;

  return (
    <>
      <div className="_form_element _field1 _full_width ">
        <input type="hidden" name="field[1]" defaultValue={utmSource} />
      </div>
      <div className="_form_element _field4 _full_width ">
        <input type="hidden" name="field[4]" defaultValue={utmMedium} />
      </div>
      <div className="_form_element _field3 _full_width ">
        <input type="hidden" name="field[3]" defaultValue={utmCampaign} />
      </div>
      <div className="_form_element _field8 _full_width ">
        <input type="hidden" name="field[90]" defaultValue={utmContent} />
      </div>
      <div className="_form_element _field8 _full_width ">
        <input type="hidden" name="field[8]" defaultValue={hsaAd} />
      </div>
      <div className="_form_element _field9 _full_width ">
        <input type="hidden" name="field[9]" defaultValue={hsaGrp} />
      </div>
      <div className="_form_element _field10 _full_width ">
        <input type="hidden" name="field[10]" defaultValue={hsaCam} />
      </div>
      <div className="_form_element _field11 _full_width ">
        <input type="hidden" name="field[11]" defaultValue={hsaSrc} />
      </div>
      <div className="_form_element _field117 _full_width ">
        <input type="hidden" name="field[184]" defaultValue={indicacaoEvento} />
      </div>
    </>
  );
}
