import styled from "styled-components";

export const Background = styled.div`
  background-image: linear-gradient(
    to bottom,
    white 16%,
    #000000 16%,
    #000000 95%,
    white 5%
  );

  @media (min-width: 1024px) {
    background-image: linear-gradient(
      to bottom,
      white 25%,
      #000000 25%,
      #000000 88%,
      white 12%
    );
  }
`;
