import DanielImg from "../../assets/img/leader-1.webp";
import HudsonImg from "../../assets/img/leader-2.webp";
import MariaImg from "../../assets/img/leader-3.webp";

export const leaders = [
  {
    title: "Daniel Kriger",
    listItems: [
      "CEO e co-fundador da Kenzie.",
      "Transforma a vida de pessoas que querem aprender programação.",
    ],
    imgList: [DanielImg],
    isLeader: true,
    imgWidth: 61,
    imgHeight: 61,
  },
  {
    title: "Hudson Carolino",
    listItems: [
      "Instrutor de ensino na Kenzie Academy.",
      "Experiência como desenvolvedor front end.",
    ],
    imgList: [HudsonImg],
    isLeader: true,
    imgWidth: 61,
    imgHeight: 61,
  },
  {
    title: "Maria Porcina",
    listItems: [
      "Facilitadora de ensino na Kenzie Academy.",
      "Experiência com HTML, CSS, JavaScript e design responsivo.",
    ],
    imgList: [MariaImg],
    isLeader: true,
    imgWidth: 61,
    imgHeight: 61,
  },
];
