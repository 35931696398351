import styled from "styled-components";
import hero from "../../assets/img/bg-hero.webp";

export const Background = styled.div`
  background: #000;
  background-size: cover;
  padding: 12px 0 1.375rem;

  @media (min-width: 768px) {
    background: #000 url(${hero}) no-repeat;
  }

  @media (min-width: 1024px) {
    background: #000 url(${hero}) no-repeat;
    padding: 110px 0 4.5rem;
  }
`;

export const Container = styled.section`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    h1 img {
      width: 606px;
      height: 270px;
      margin-top: 1.5rem;
    }
  }
`;

export const Module = styled.p`
  font-family: "JetBrains Mono", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  background: var(--highlight-color);
  color: var(--text-color-secondary);
  text-transform: uppercase;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  font-family: "JetBrains Mono", sans-serif;
  font-size: 1.25rem;
  padding-bottom: 0.375rem;
  color: var(--text-color-secondary);
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 1024px) {
    padding-bottom: 2.5rem;
    font-size: 2.5rem;
    max-width: 53.125rem;
  }
`;
