import CttImg1 from "../../assets/img/content-img-1.webp";
import CttImg2 from "../../assets/img/content-img-2.webp";
import CttImg3 from "../../assets/img/content-img-3.webp";
import CttImg4 from "../../assets/img/content-img-4.webp";
import CttImg5 from "../../assets/img/content-img-5.webp";

export const contentList = [
  {
    title: "Desperte para o mercado da tecnologia",
    listItems: [
      "Um desafio que vai te preparar para entrar de vez no mercado da tecnologia.",
    ],
    imgList: [CttImg1],
    isLeader: false,
    imgWidth: 50,
    imgHeight: 50,
  },
  {
    title: "Dê os seus primeiros passos",
    listItems: ["Aprenda o básico de HTML, CSS e JavaScript."],
    imgList: [CttImg2, CttImg3, CttImg4],
    isLeader: false,
    imgWidth: 46,
    imgHeight: 50,
  },
  {
    title: "Code seu projeto profissional",
    listItems: ["O passo a passo explicado para o seu primeiro projeto."],
    imgList: [CttImg5],
    isLeader: false,
    imgWidth: 46,
    imgHeight: 50,
  },
];
