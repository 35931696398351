import styled from "styled-components";
import BackgroundFrame from "../../assets/img/frame-desktop.webp";
import BackgroundFrameMobile from "../../assets/img/frame-mobile.webp";

export const Container = styled.section`
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
`;

export const Title = styled.h2`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 85%;
  font-size: 1.625rem;
  margin-bottom: 2.5rem;
  font-weight: 800;
  color: var(--bg-secondary);

  @media (min-width: 1024px) {
    margin-bottom: 4.5rem;
    font-size: 2rem;
  }
`;

export const Content = styled.ul`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 85%;
  li + li {
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;

    li + li {
      margin-top: 0;
    }
  }
`;

export const Frame = styled.div`
  width: 100%;
  background-image: url(${BackgroundFrameMobile});
  background-repeat: repeat-x;
  background-position: center;
  background-size: 300%;

  @media (min-width: 540px) {
    background-image: url(${BackgroundFrame});
    background-size: 200%;
  }

  @media (min-width: 1024px) {
    background-position: center bottom;
    background-size: 100%;
  }

  @media (min-width: 1450px) {
    background-size: 85%;
  }
  @media (min-width: 1700px) {
    background-size: 65%;
  }
`;
