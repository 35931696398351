import { Container, Title, Content, Frame } from "./styles";
import Card from "../Card";

import { leaders } from "../../shared/utils/leaders";

export default function LeadersSection() {
  return (
    <Container>
      <Title>CONHEÇA OS LÍDERES DA RESISTÊNCIA</Title>

      <Frame>
        <Content>
          {leaders.map((item) => (
            <Card
              title={item.title}
              paragraph={item.listItems}
              listImages={item.imgList}
              isLeader={item.isLeader}
              imgHeight={item.imgHeight}
              imgWidth={item.imgWidth}
              key={item.title}
            />
          ))}
        </Content>
      </Frame>
    </Container>
  );
}
