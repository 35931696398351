type SetCookieProps = {
  name: string;
  value: string;
  opts?: string;
};

export function getCookie(cookieName: string) {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName))
    ?.split("=")[1];
}

export function setCookie({ name, value, opts = "" }: SetCookieProps) {
  document.cookie = `${name}=${value}; Domain=${process.env.REACT_APP_DOMAIN}; SameSite=strict; Secure; ${opts}`;
}
