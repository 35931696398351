import styled from "styled-components";

interface HeaderProps {
  fixed: boolean;
}

export const StyledHeader = styled.header<HeaderProps>`
  display: ${({ fixed }) => (fixed ? "block" : "none")};
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  border-bottom: 1px solid #696969;
  background-color: ${({ fixed }) =>
    fixed ? "var(--bg-secondary)" : "transparent"};
  transition: background 0.3s ease;

  img {
    object-fit: contain;
  }

  button {
    font-family: "Jet Brains", monospace;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    max-width: 40%;
    padding: 0.5rem 0;
    border-radius: var(--btn-border-radius);
    border: ${({ fixed }) =>
      fixed ? "1px solid transparent" : "1px solid #fff"};
    color: var(--text-color-secondary);
    background-color: ${({ fixed }) =>
      fixed ? "var(--highlight-color)" : "transparent"};
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      box-shadow: 0 0 8px 4px var(--highlight-color);
    }
  }

  @media (min-width: 686px) {
    button {
      padding: 1.2rem;
      max-width: unset;
    }
  }

  @media (min-width: 1024px) {
    height: 110px;
    display: block;

    img {
      width: 153px;
      height: 135px;
      position: absolute;
      left: 0;
      bottom: -25px;
    }
  }
`;

export const Container = styled.div`
  max-width: var(--max-width);
  width: 85%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    position: relative;
    justify-content: flex-end;
  }
`;
