import styled from "styled-components";
import { StudentTestimonial } from "../../shared/models/student";
import PlayIcon from "../../assets/img/play-icon.webp";
import { css } from "styled-components";

interface ContainerProps {
  student: StudentTestimonial;
  height?: number;
}

interface TitleContentProps {
  student: StudentTestimonial;
  active: boolean;
}

export const Container = styled.article<ContainerProps>`
  width: 100%;
  height: 100%;
  background-color: var(--white);
  color: var(--text-color);
  font-size: 0.75rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 4px 3px rgb(0 0 0 / 20%);
  border-radius: var(--border-radius);
  padding: ${({ student }) => (student?.video ? "0" : "1rem 1.5rem")};
  flex-direction: column;
  position: relative;

  ${({ student }) =>
    student?.video &&
    css`
      overflow: hidden;
    `}
  @media (min-width: 1024px) {
    padding: ${({ student }) => (student?.video ? "0" : "1.5rem 1.5rem")};
    height: ${({ height }) => (height ? `${height}rem` : "100%")};
  }

  .yt-lite {
    height: 100%;
  }
  .lty-playbtn {
    position: absolute;
    bottom: 5px;
    left: 15px;
    cursor: pointer;
    background-color: transparent;
    background-image: url(${PlayIcon});
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    background-size: contain;
  }
  .yt-lite:hover .lty-playbtn,
  .lty-playbtn:hover {
    background-color: transparent;
  }
  .lty-playbtn::before {
    border-color: none;
    border-style: none;
    border-width: none;
  }
  .yt-lite > .lty-playbtn,
  .yt-lite > .lty-playbtn:before {
    top: 75%;
    left: 20px;
    transform: none;

    @media (min-width: 650px) and (max-width: 1023px) {
      ${({ student }) =>
        student.name === "Julia Sperandio" &&
        css`
          top: 85%;
        `}
    }
  }
`;

export const TestimonialHeader = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  img {
    margin-right: 1rem;
    object-fit: contain;
  }
`;

export const TestimonialContent = styled.p`
  font-size: 0.75rem;

  @media (min-width: 1400px) {
    font-size: 0.81rem;
  }
`;

export const TitleContent = styled.div<TitleContentProps>`
  ${({ student }) =>
    student.video &&
    css`
      position: absolute;
      bottom: 5px;
      right: 15px;
    `}
  z-index: 1;
  display: ${({ active }) => !active && "none"};
  h3 {
    margin-bottom: 0.25rem;
    color: ${({ student }) =>
      student.video ? "var(--text-color-secondary)" : "#0000ff"};
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    line-height: 1.2rem;
  }
  span {
    display: block;
    color: ${({ student }) =>
      student.video ? "var(--text-color-secondary)" : "var(--text-color)"};
  }

  @media (min-width: 1400px) {
    font-size: 0.9rem;
  }
`;
