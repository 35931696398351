import styled from "styled-components";

export const Container = styled.section`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 85%;
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    gap: 4.375rem;
    align-items: center;
  }
`;

export const MatrixContent = styled.div`
  img {
    display: block;
    object-fit: contain;
    margin: 0 auto;

    @media (min-width: 1024px) {
      width: 349px;
      height: 348px;
    }
  }

  h2 {
    font-size: 1.625rem;
    padding: 2.5rem 0;
    font-weight: 800;
    color: var(--bg-secondary);

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

export const TextContainer = styled.article`
  p + p {
    margin-top: 1rem;

    @media (min-width: 1024px) {
      margin-top: 2.125rem;
    }
  }

  p {
    font-size: 1rem;
    max-width: 700px;

    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }
`;

export const StyledStrong = styled.strong`
  display: block;
  margin-top: 1rem;

  @media (min-width: 1024px) {
    margin-top: 2.125rem;
    font-size: 1.5rem;
  }
`;

export const TitleDesktop = styled.h2`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    margin-bottom: 4.5rem;
    font-weight: 800;
    font-size: 2rem;
    color: var(--bg-secondary);
  }
`;
