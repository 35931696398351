import styled from "styled-components";

export const Container = styled.section`
  background-color: var(--bg-main);
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2.5rem 0;
  overflow-x: hidden;

  h2.title {
    margin-bottom: 2.5rem;
    font-weight: 800;
    font-size: 1.625rem;
  }

  @media (min-width: 1024px) {
    padding: 4.5rem 0;

    h2.title {
      font-size: 2rem;
      margin-bottom: 4.5rem;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  div.MuiAccordion-rounded {
    box-shadow: none;
    li,
    h3,
    span {
      margin: 0.5rem 0;
    }

    h3 {
      font-size: 1rem;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;

      @media (min-width: 1024px) {
        font-size: 1.625rem;
      }
    }
    span {
      font-weight: 700;
    }
  }
  div.MuiAccordionSummary-root {
    padding: 0;

    @media (min-width: 1024px) {
      padding: 1rem 0;
    }
  }

  @media (min-width: 1024px) {
    .MuiAccordionDetails-root {
      font-size: 1.25rem;
    }
  }
`;

export const List = styled.ul`
  padding-left: 2rem;

  li {
    list-style: disc;
    margin-top: 1rem;
  }
`;
