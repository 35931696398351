import { Container, Header, List } from "./styles";

interface ConteudoCardProps {
  title: string;
  paragraph: string[];
  listImages: string[];
  isLeader: boolean;
  imgWidth: number;
  imgHeight: number;
}
export default function Card({
  title,
  paragraph,
  listImages,
  isLeader,
  imgWidth,
  imgHeight,
}: ConteudoCardProps) {
  return (
    <Container isLeader={isLeader}>
      <Header isLeader={isLeader}>
        {listImages.map((image, index) => (
          <img
            src={image}
            alt="Imagem ilustrativa"
            key={`${title}${index}`}
            width={imgWidth}
            height={imgHeight}
          />
        ))}

        <h3>{title}</h3>
      </Header>

      <h3>{title}</h3>
      <List isLeader={isLeader}>
        {paragraph.map((p) => (
          <li key={p}>{p}</li>
        ))}
      </List>
    </Container>
  );
}
