import { Header, LogoContainer, TitleContainer, Steps, Step } from "./styles";
import logo from "../../assets/img/logo.webp";
import Footer from "../../components/Footer";

export default function ThankYou() {
  return (
    <>
      <Header>
        <LogoContainer>
          <div>
            <img src={logo} alt="Logo Kenzie" width="127" height="113" />
          </div>
        </LogoContainer>
        <TitleContainer>
          <h1>
            O seu despertar começou! <br />
            Logo abaixo você terá acesso a plataforma de conteúdo de Introdução
            a Programação.
          </h1>
        </TitleContainer>
      </Header>
      <Steps>
        <Step color="blue">
          <h2>PASSO 1/2</h2>
          <strong>
            Aproveitando que você tem interesse em entrar na área de
            programação, a Kenzie oferece uma formação completa, que conta com
            94% de empregabilidade dos formados!
          </strong>
          <p>
            Que tal se tornar um profissional completo na área em apenas 12
            meses?
          </p>
          <p>
            Milhares de alunos já aproveitaram a oportunidade. Temos diversas
            formas de pagamento para você!
          </p>
          <p>
            Gostou da ideia? Então toque no botão abaixo para conhecer tudo
            sobre a nossa formação de programador completo e se inscrever no
            nosso processo seletivo:
          </p>
          <a
            href="https://hi.switchy.io/inscricao-typ-cdv"
            rel="noreferrer"
            target="_blank"
          >
            ENTRAR NO MERCADO DE PROGRAMAÇÃO
          </a>
        </Step>
        <Step color="purple">
          <h2>PASSO 2/2</h2>
          <strong>Acesse a Plataforma do Desafio da Virada</strong>
          <p>
            Para acessar todo o conteúdo e e fazer o seu primeiro projeto,
            acesse nossa plataforma interativa de conteúdo tocando no botão
            abaixo:
          </p>
          <a
            href="https://hi.switchy.io/typ-cdv"
            rel="noreferrer"
            target="_blank"
          >
            ACESSAR A PLATAFORMA
          </a>
        </Step>
        <hr />
      </Steps>
      <Footer />
    </>
  );
}
