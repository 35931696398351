import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import Recommendation from "../pages/Recommendation";
import ThankYou from "../pages/ThankYou";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/obrigado" element={<ThankYou />}></Route>
      <Route path="/indicacao" element={<Recommendation />}></Route>
      <Route path="*" element={<Navigate replace to="/" />}></Route>
    </Routes>
  );
}
