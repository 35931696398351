import { useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import MatrixSection from "../../components/MatrixSection";
import ContentsSection from "../../components/ContentsSection";
import LeadersSection from "../../components/LeadersSection";
import TestimonialsSection from "../../components/TestimonialsSection";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import { Background } from "./styles";
import { useFirstInteractionFields } from "../../hooks/useFirstInteractionFields";

export default function LandingPage() {
  const { setFirstInteractionInCookies } = useFirstInteractionFields();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const ref = useRef<HTMLDivElement>(null);

  function scrollToRef() {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }

  setFirstInteractionInCookies(params);
  return (
    <>
      <Header onClick={scrollToRef} />
      <main>
        <Hero refProp={ref} />
        <MatrixSection />
        <Background>
          <ContentsSection />
          <LeadersSection />
        </Background>
        <TestimonialsSection />
        <FAQ />
      </main>
      <Footer />
    </>
  );
}
