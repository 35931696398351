import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GrCopy } from "react-icons/gr";
import { AiOutlineFileDone } from "react-icons/ai";
import {
  FaWhatsapp,
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
} from "react-icons/fa";
import {
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from "react-share";
import {
  Container,
  Header,
  Main,
  Text,
  LinkGeneratorContainer,
  Input,
  Button,
  CopyContainer,
  SocialContainer,
  Box,
  Share,
  Footer,
} from "./styles";
import titleMobile from "../../assets/img/title-mobile.webp";
import title from "../../assets/img/title.webp";
import logo from "../../assets/img/logo-black.webp";

export default function Recommendation() {
  const [email, setEmail] = useState<string>("");
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const shareUrl = `https://desafio.kenzie.com.br/?utm_source=indicacao&utm_campaign=codigodavirada&utm_medium=organico&indicacao_evento=${email}`;
  const message =
    "Olá! Gostaria de te convidar para se inscrever no Código da Virada, evento 100% gratuito e online para quem deseja dar os primeiros passos na programação e conquistar um certificado, além de um projeto partindo do zero. Eu já despertei para o mercado de tecnologia e você, está pronto? Basta clicar no link e se inscrever:";

  return (
    <Container>
      <Header>
        <h1 title="Código da Virada">
          <picture>
            <source media="(min-width: 1024px)" srcSet={title} />
            <img
              src={titleMobile}
              alt="Código da Virada"
              width="223"
              height="152"
            />
          </picture>
        </h1>
      </Header>
      <Main>
        <Text>
          Chegou a hora de ajudar mais pessoas a despertar! Basta inserir no
          campo abaixo{" "}
          <strong>o mesmo email que você se cadastrou no Desafio</strong> e
          depois compartilhar nas suas redes sociais e grupos de amigos.
        </Text>
        <LinkGeneratorContainer>
          <Input
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            disabled={hasGenerated}
          />
          <Button
            disabled={!email || hasGenerated}
            onClick={() => setHasGenerated(true)}
            success={hasGenerated}
          >
            {hasGenerated ? "Link gerado com sucesso!" : "Gerar link"}
          </Button>
        </LinkGeneratorContainer>
        <CopyContainer>
          {hasGenerated ? (
            <span>{shareUrl} </span>
          ) : (
            <span>(Seu link aparecerá aqui)</span>
          )}
          <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
            {copied ? (
              <button disabled>
                <AiOutlineFileDone /> COPIADO!
              </button>
            ) : (
              <button disabled={!hasGenerated}>
                <GrCopy /> COPIAR
              </button>
            )}
          </CopyToClipboard>
        </CopyContainer>
        <SocialContainer>
          <Box>
            <span>
              Compartilhe
              <br /> no Whatsapp
            </span>
            {hasGenerated ? (
              <WhatsappShareButton
                url={shareUrl}
                title={message}
                style={{
                  fontSize: "2.5rem",
                  color: "var(--text-color-secondary)",
                  backgroundColor: "#25d366",
                  padding: "1rem 4rem",
                  marginTop: "0.5rem",
                  borderRadius: "var(--btn-border-radius)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaWhatsapp />
              </WhatsappShareButton>
            ) : (
              <Share>
                <FaWhatsapp />
              </Share>
            )}
          </Box>
          <Box>
            <span>
              Compartilhe
              <br /> no Facebook
            </span>
            {hasGenerated ? (
              <FacebookShareButton
                url={shareUrl}
                quote={message}
                style={{
                  fontSize: "2.5rem",
                  color: "var(--text-color-secondary)",
                  backgroundColor: "#1877f2",
                  padding: "1rem 4rem",
                  marginTop: "0.5rem",
                  borderRadius: "var(--btn-border-radius)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaFacebookF />
              </FacebookShareButton>
            ) : (
              <Share>
                <FaFacebookF />
              </Share>
            )}
          </Box>
          <Box>
            <span>
              Compartilhe
              <br /> no Twitter
            </span>
            {hasGenerated ? (
              <TwitterShareButton
                url={shareUrl}
                title="Estou participando do Código da Virada, evento gratuito e online para quem deseja dar os primeiros passos na programação. Eu já despertei para o mercado de tecnologia e você, está pronto? Clique no link e se inscreva:"
                style={{
                  fontSize: "2.5rem",
                  color: "var(--text-color-secondary)",
                  backgroundColor: "#00acee",
                  padding: "1rem 4rem",
                  marginTop: "0.5rem",
                  borderRadius: "var(--btn-border-radius)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaTwitter />
              </TwitterShareButton>
            ) : (
              <Share>
                <FaTwitter />
              </Share>
            )}
          </Box>
          <Box>
            <span>
              Compartilhe
              <br /> no Linkedin
            </span>
            {hasGenerated ? (
              <LinkedinShareButton
                url={shareUrl}
                style={{
                  fontSize: "2.5rem",
                  color: "var(--text-color-secondary)",
                  backgroundColor: "#0e76a8",
                  padding: "1rem 4rem",
                  marginTop: "0.5rem",
                  borderRadius: "var(--btn-border-radius)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaLinkedinIn />
              </LinkedinShareButton>
            ) : (
              <Share>
                <FaLinkedinIn />
              </Share>
            )}
          </Box>
        </SocialContainer>
      </Main>
      <Footer>
        <img src={logo} alt="Logo Kenzie" width="72" height="60" />
      </Footer>
    </Container>
  );
}
