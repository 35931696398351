import styled from "styled-components";

interface ContainerProps {
  isLeader: boolean;
}

export const Container = styled.li<ContainerProps>`
  border-radius: 20px;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 20%);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${({ isLeader }) =>
    isLeader ? "#171717" : "var(--bg-main)"};

  @media (min-width: 1024px) {
    padding: 2.5rem 1.5rem;
    gap: 1.25rem;
  }

  h3 {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    display: ${({ isLeader }) => isLeader && "none"};

    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1rem;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 1024px) {
    max-width: 280px;
  }

  @media (min-width: 1100px) {
    max-width: 310px;
  }

  @media (min-width: 1250px) {
    max-width: none;
    width: 350px;
    height: 351px;
  }
`;

export const Header = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    img {
      height: ${({ isLeader }) => (isLeader ? "83px" : "70px")};
      width: ${({ isLeader }) => (isLeader ? "83px" : "64px")};
    }
  }

  h3 {
    display: ${({ isLeader }) => (isLeader ? "block" : "none")};
    margin-left: 1rem;
    font-size: 1.5rem;
    color: var(--text-color-secondary);
    width: 115px;

    @media (min-width: 1024px) {
    }
  }
`;

export const List = styled.ul<ContainerProps>`
  display: flex;
  flex-direction: column;

  li {
    list-style: disc;
    margin-left: 1.25rem;
    font-size: 1rem;
    color: ${({ isLeader }) =>
      isLeader ? "var(--text-color-secondary)" : "var(--text-color-main)"};
    margin-top: ${({ isLeader }) => (isLeader ? "1rem" : "0.5rem")};

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 1024px) {
    li + li {
      padding-top: ${({ isLeader }) => (isLeader ? "1rem" : "0.5rem")};
    }
  }
`;
