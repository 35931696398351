import styled, { css } from "styled-components";

interface Props {
  secondary: boolean;
}

export const Container = styled.div``;

export const Form = styled.form<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  div.boxes {
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  span.errors {
    font-size: 1rem;
    margin: 1rem 0;
    text-align: center;
    color: lightcoral;
    width: 100%;

    @media (min-width: 1024px) {
      ${({ secondary }) =>
        secondary &&
        css`
          width: 22.5rem;
        `}
    }
  }

  p.terms {
    font-size: 0.75rem;
    text-align: center;
    margin-top: 1.375rem;
    color: #8e8e8e;

    @media (min-width: 1024px) {
      margin-top: 2.5rem;

      ${({ secondary }) =>
        secondary &&
        css`
          width: 22.5rem;
        `}
    }

    a {
      color: #8e8e8e;
      text-decoration: underline;
    }
  }

  input {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    font-size: 1.2rem;
    background-color: ${({ secondary }) => (secondary ? "#f0f0f0" : "#fff")};
    border: 1px solid transparent;
    border-radius: var(--btn-border-radius);
    outline: none;
    margin-bottom: 1.375rem;

    &:focus {
      border-color: var(--highlight-color);
    }

    @media (min-width: 400px) {
      font-size: 1.25rem;
    }

    @media (min-width: 1024px) {
      width: ${({ secondary }) => (secondary ? "22.5rem" : "21.25rem")};
      font-size: 1.125rem;
      margin: ${({ secondary }) => (secondary ? "0 0 1rem 0" : "0 1rem 0 0")};
    }
  }
  button {
    font-family: "JetBrains Mono", monospace;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    background-color: var(--highlight-color);
    border-radius: var(--btn-border-radius);
    color: var(--text-color-secondary);
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      box-shadow: 0 0 8px 4px var(--highlight-color);
    }

    @media (min-width: 400px) {
      font-size: 1.25rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.375rem;
      width: ${({ secondary }) => (secondary ? "22.5rem" : "54.5rem")};
      margin-top: ${({ secondary }) => (secondary ? "0" : "1rem")};
    }
  }
`;

export const InputContainer = styled.div<Props>`
  @media (min-width: 1024px) {
    ${({ secondary }) =>
      secondary
        ? css`
            display: flex;
            flex-direction: column;
          `
        : css`
            width: 54.5rem;
            display: flex;
            justify-content: space-between;
          `}
  }
`;
