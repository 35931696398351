import styled from "styled-components";
import hero from "../../assets/img/bg-hero.webp";
import heroMobile from "../../assets/img/bg-hero-mobile.webp";

interface StepContentProps {
  color: string;
}

export const Header = styled.header`
  background: #000 url(${heroMobile}) no-repeat;
  background-size: cover;

  @media (min-width: 429px) {
    background-image: url(${hero});
  }
`;

export const LogoContainer = styled.div`
  text-align: center;
  padding-top: 1rem;

  img {
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    padding-top: 0;
    border-bottom: 1px solid #696969;

    div {
      width: 85%;
      height: 110px;
      max-width: var(--max-width);
      margin: 0 auto;
      position: relative;
      img {
        width: 153px;
        height: 135px;
        position: absolute;
        left: 0;
        bottom: -25px;
      }
    }
  }
`;

export const TitleContainer = styled.div`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem 0 2rem;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: var(--text-color-secondary);
    text-align: center;
  }

  @media (min-width: 1024px) {
    padding: 5.35rem 0;

    h1 {
      font-size: 1.75rem;
      text-align: left;
      line-height: 2;
    }
  }
`;

export const Steps = styled.main`
  display: flex;
  flex-direction: column;

  hr {
    border: 1px solid #d8d8d8;
    width: 99%;

    @media (min-width: 1024px) {
      width: 85%;
      max-width: var(--max-width);
    }
  }
`;

export const Step = styled.section<StepContentProps>`
  max-width: var(--max-width);
  margin: 0 auto;
  margin-bottom: 2.5rem;
  width: 85%;

  @media (min-width: 1024px) {
    margin-bottom: 4.5rem;
  }

  h2 {
    font-size: 1.625rem;
    padding: 2.5rem 0;
    color: ${({ color }) => (color === "purple" ? "#9745FF" : "#0000FF")};
    font-weight: 800;

    @media (min-width: 1024px) {
      font-size: 2rem;
      padding: 4rem 0;
    }
  }

  strong {
    display: block;
    margin-bottom: 2rem;
    font-size: 1rem;
    color: var(--text-color-main);

    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }

  p {
    margin-bottom: 2rem;
    font-size: 1rem;
    color: var(--text-color-main);

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }

  a {
    font-family: "JetBrains Mono", monospace;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    background-color: ${({ color }) =>
      color === "purple" ? "#9745FF" : "#0000FF"};
    color: var(--text-color-secondary);
    border-radius: var(--btn-border-radius);
    transition: all 0.3s ease-in-out;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      filter: brightness(0.85);
    }

    @media (min-width: 400px) {
      font-size: 1.25rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.375rem;
      width: 31.125rem;
    }
  }
`;
