import {
  TestimonialColumn,
  Testimonials,
  Container,
  TestimonialsContent,
} from "./styles";
import TestimonialCard from "../TestimonialCard";

import {
  testimonialsC1,
  testimonialsC2,
  testimonialsC3,
  testimonialsC4,
} from "../../shared/utils/testimonials";

export default function TestimonialsSection() {
  return (
    <Container>
      <TestimonialsContent>
        <h2>DEPOIMENTOS DE QUEM JÁ ROMPEU COM A MATRIX</h2>
        <Testimonials>
          <TestimonialColumn>
            {testimonialsC1.map((t) => (
              <TestimonialCard
                student={t}
                height={t.videoHeight || 35}
                key={t.name}
              />
            ))}
          </TestimonialColumn>
          <TestimonialColumn className="column-display">
            {testimonialsC2.map((t) => (
              <TestimonialCard student={t} key={t.name} />
            ))}
          </TestimonialColumn>
          <TestimonialColumn className="column-display">
            {testimonialsC3.map((t) => (
              <TestimonialCard student={t} key={t.name} />
            ))}
          </TestimonialColumn>
          <TestimonialColumn className="column-display">
            {testimonialsC4.map((t) => (
              <TestimonialCard
                student={t}
                height={t.videoHeight || 35}
                key={t.name}
              />
            ))}
          </TestimonialColumn>
        </Testimonials>
      </TestimonialsContent>
    </Container>
  );
}
