import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body, div, p, header, footer, main, section, nav, ul, li, a, button, input, img, article, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    border: none;
}

:root {
    --bg-main: #fff;
    --bg-secondary: #000000;
    --text-color-main: #2d2d2d;
    --text-color-secondary: #ffffff;
    --highlight-color: #112EF7;
    --max-width: 1120px;
    --border-radius: 20px;
    --btn-border-radius: 5px;
}

html {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
}

body, button, a, input {
    font-family: "Montserrat", sans-serif;
    color: var(--text-color-main);
}

h1, h2, h3, h4, h5, h6 {
    font-family: "JetBrains Mono", monospace;
}

p {
    line-height: 1.4;
}

button {
    cursor: pointer;
}
`;
