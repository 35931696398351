import styled, { css } from "styled-components";
import bg from "../../assets/img/bg-recommendation.webp";

interface ButtonProps {
  success: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    background: #000 url(${bg}) no-repeat;
    background-size: cover;
  }
`;

export const Header = styled.header`
  text-align: center;
  padding-top: 1rem;

  h1 {
    line-height: 0;
  }

  @media (min-width: 1024px) {
    padding-top: 0;

    h1 img {
      width: 477px;
      height: 326px;
    }
  }
`;

export const Main = styled.main`
  width: 85%;
  max-width: 840px;
  margin: 0 auto;
  flex-grow: 1;
`;

export const Text = styled.p`
  color: var(--text-color-secondary);
  text-align: center;
  margin-bottom: 1.5rem;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

export const LinkGeneratorContainer = styled.div`
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: var(--btn-border-radius);
  outline: none;
  margin-bottom: 1.5rem;

  &:focus {
    border-color: var(--highlight-color);
  }

  @media (min-width: 400px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    margin: 0 1.5rem 0 0;
  }
`;

export const Button = styled.button<ButtonProps>`
  font-family: "JetBrains Mono", monospace;
  font-size: 1rem;
  font-weight: 800;
  color: ${({ success }) =>
    success ? "var(--highlight-color)" : "var(--text-color-main)"};
  text-transform: uppercase;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  background-color: ${({ success }) =>
    success ? "transparent" : "var(--highlight-color)"};
  border-radius: var(--btn-border-radius);
  transition: all 0.3s ease-in-out;

  ${({ success }) =>
    success &&
    css`
      border: 1px solid var(--highlight-color);
    `}

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    box-shadow: 0 0 8px 4px rgba(64, 255, 4, 0.4);
  }

  @media (min-width: 400px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.375rem;
  }
`;

export const CopyContainer = styled.div`
  color: #b6b6b6;
  background-color: #373737;
  border-radius: var(--btn-border-radius);
  padding: 1rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    word-break: break-all;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    padding: 1rem 2rem;

    span {
      width: 74%;
    }
  }

  button {
    font-family: "Jet Brains", monospace;
    font-weight: 800;
    font-size: 1rem;
    text-decoration: underline;
    color: #b6b6b6;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0;
    margin-top: 0.5rem;
    transition: color 0.3s ease;

    svg {
      margin-right: 0.2rem;
    }

    svg path {
      stroke: #b6b6b6;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: var(--highlight-color);

      svg path {
        stroke: var(--highlight-color);
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: initial;
    }

    @media (min-width: 400px) {
      font-size: 1.25rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.375rem;

      svg {
        margin-right: 1rem;
        font-size: 2rem;
      }
    }
  }
`;

export const SocialContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5rem;

  span:first-child {
    font-size: 1.375rem;
    font-family: "Jet Brains", monospace;
    text-transform: uppercase;
    color: var(--text-color-secondary);
    font-weight: 800;
  }

  button svg {
    transition: color 0.3s ease-in-out;
  }

  button:hover svg,
  button:focus svg {
    color: #000;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Share = styled.span`
  font-size: 2.5rem;
  color: var(--text-color-secondary);
  padding: 1rem 4rem;
  margin-top: 0.5rem;
  background: linear-gradient(
    90deg,
    #050505 0%,
    rgba(14, 118, 168, 0.5) 60%,
    #050505 100%
  );
  border-radius: var(--btn-border-radius);
  display: flex;
  align-items: center;
`;

export const Footer = styled.footer`
  border-top: 1px solid #696969;
  text-align: center;
  margin-top: 4rem;
  height: 60px;

  img {
    object-fit: contain;
    transform: translateY(-25px);
  }

  @media (min-width: 1024px) {
    margin-top: 6rem;
    height: 90px;

    img {
      width: 123px;
      height: 109px;
      transform: translateY(-45px);
    }
  }
`;
